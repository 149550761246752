import { toRem } from '../../../../helpers'

const Style = ({ variant, isRecipeStep }) => {
    const config = {
        '1x1': {
            minHeight: '250px',
            image: 'url(/banners/ad-wave-small.png)',
            size: '410px 105px'
        },
        '1x2': {
            minHeight: '520px',
            image: 'url(/banners/ad-wave-big.png)',
            size: '410px 255px'
        },
        '2x1': {
            minHeight: '250px',
            image: 'url(/banners/ad-wave-big.png)',
            size: '410px 255px'
        }
    }

    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minHeight: config[variant].minHeight,
        backgroundImage: config[variant].image,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: config[variant].size,
        ...(isRecipeStep && { marginBottom: toRem(32) }),
        '.adunitContainer': {
            position: 'relative',
            width: '100% !important',
            height: '100% !important',
            backgroundImage: config[variant].image,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: config[variant].size,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        '@media print': {
            display: 'none'
        }
    }
}

export default Style
