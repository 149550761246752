/* eslint-disable camelcase */
// @TODO: This component will be a work in progress for a long time
// @TODO: Edit button functionality
// @TODO: Check if edit is available for the logged in user (user or admin)
// @TODO: Provjeriti sto sa suffix i prefixom kad je heading label komponenta izmjenjena

import { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Media } from '@hmn/coolinarika-web-core/controllers/MediaContext'
import cardVariationHandler from '@hmn/coolinarika-web-core/helpers/card'
import excerpt from '@hmn/coolinarika-web-core/helpers/excerpt'
import { md, useBreakpoints, useTaxonList, useUser } from '@hmn/coolinarika-web-core/hooks'

import { ParallaxBanner } from '../../../Banner/components/Parallax'
import { Button, buttonSizes, buttonVariants } from '../../../Button'
import { Card, cardRoundedVariants } from '../../../Card'
import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { withErrorBoundary } from '../../../ErrorBoundary'
import {
    HeadingLabel,
    headingLabelSizes,
    headingLabelVariants,
    HeadingSection,
    headingSectionSizes
} from '../../../Heading/components'
import { Highlight, highlightSizes } from '../../../Highlight'
import { EditIcon, Icon } from '../../../Icon'
import { Image } from '../../../Image'
import styles from './Card.style'

const InspirationCardStyled = styled.div(props => ({ ...styles(props) }))

const inspirationCardVariants = Object.freeze({
    '1x1': '1x1',
    '1x2': '1x2',
    '2x1': '2x1',
    '2x2': '2x2'
})

/**
 * Unsluggifies and capitalizes inspiration title.
 *
 * @param {*} title
 * @return {*}
 */
const prettifyTitle = title => {
    const result = title.replace(/-/g, ' ')
    return `${result.charAt(0).toUpperCase()}${result.substr(1)}`
}

const InspirationCard = ({
    item,
    imageId: propImageId,
    imageWidth,
    imageHeight,
    itemId: propId,
    title: propTitle,
    titleTagComponent: TitleTag,
    lead: propLead,
    href,
    hrefAs,
    authorId: propAuthorId,
    className,
    categoryName,
    label,
    labelVariant,
    variant,
    overrideImagePadding,
    fullHeight,
    isParallaxed,
    ...rest
}) => {
    const [isMobileUp] = useBreakpoints(md)
    const { data } = useTaxonList('categories', {
        entity: 'articles',
        entityId: propId || item?.content_id
    })

    const { id: loggedInUserId } = useUser()

    const {
        _id = propId,
        author,
        owned_by_user_profile,
        title: itemTitle,
        lead = propLead,
        image: itemImage
    } = useMemo(() => item || {}, [item])
    const { id: userProfileId = propAuthorId } = owned_by_user_profile || {}
    const { id: authorId = userProfileId } = author || {}
    const { id: itemImageId } = itemImage || {}

    const imageId = propImageId || itemImageId

    const title = propTitle || itemTitle
    const category = data?.[0]?.taxon.title || categoryName

    const edit = authorId && loggedInUserId && authorId === loggedInUserId

    const handleEditClick = useCallback(() => {
        // placeholder function for profile edit button
        /* eslint-disable-next-line */
        alert('TODO: Edit button click action.')
    }, [])

    const cardActions = useCallback(
        () =>
            edit ? (
                <Button
                    variant={buttonVariants.SECONDARY}
                    size={buttonSizes.SMALL}
                    isIconOnly
                    title="Uredi"
                    onClick={handleEditClick}>
                    <Icon icon={EditIcon} size={24} />
                </Button>
            ) : null,
        [edit, handleEditClick]
    )

    const inspirationLabelSizeVariants = useMemo(
        () =>
            Object.freeze({
                '1x1': headingLabelSizes.SMALL,
                '1x2': headingLabelSizes.SMALL,
                '2x1': headingLabelSizes.SMALL,
                '2x2': headingLabelSizes[isMobileUp ? 'LARGER' : 'SMALL']
            }),
        [isMobileUp]
    )

    const inspirationHighlightSizeVariants = useMemo(
        () =>
            Object.freeze({
                '1x1': highlightSizes[isMobileUp ? 'SMALL' : 'NORMAL'],
                '1x2': highlightSizes[isMobileUp ? 'NORMAL' : 'LARGE'],
                '2x1': highlightSizes[isMobileUp ? 'NORMAL' : 'SMALL'],
                '2x2': highlightSizes[isMobileUp ? 'LARGE' : 'NORMAL']
            }),
        [isMobileUp]
    )

    const HighLightContent = useCallback(() => {
        let template = null

        if (variant !== inspirationCardVariants['2x1']) {
            template = (
                <Highlight
                    size={inspirationHighlightSizeVariants[variant]}
                    TagComponent={TitleTag}
                    className="cardContent_title">
                    {prettifyTitle(title)}
                </Highlight>
            )
        } else if (variant === inspirationCardVariants['2x1']) {
            template = (
                <>
                    <Media lessThan="md">
                        <Highlight
                            size={inspirationHighlightSizeVariants[variant]}
                            TagComponent={TitleTag}
                            className="cardContent_title">
                            {prettifyTitle(title)}
                        </Highlight>
                    </Media>
                    <Media greaterThan="sm">
                        <HeadingSection
                            size={headingSectionSizes.SMALLER}
                            className="cardContent_title cardPicture_title">
                            {title}
                        </HeadingSection>
                    </Media>
                </>
            )
        } else {
            template = (
                <HeadingSection size={headingSectionSizes.SMALLER} className="cardContent_title cardPicture_title">
                    {title}
                </HeadingSection>
            )
        }

        return template
    }, [inspirationLabelSizeVariants, inspirationHighlightSizeVariants, variant, title])

    if (!title && !imageId) {
        return null
    }

    return (
        <Card
            title={title}
            href={href}
            hrefAs={hrefAs}
            className={className}
            actionComponent={cardActions}
            rounded={cardRoundedVariants.MEDIUM}
            fullHeight={fullHeight}
            {...rest}>
            <InspirationCardStyled
                variant={variant}
                inspirationCardVariants={inspirationCardVariants}
                overlay={!!lead}
                overrideImagePadding={overrideImagePadding}
                fullHeight={fullHeight}>
                <ConditionalWrapper condition={isParallaxed} Wrapper={ParallaxBanner} height={imageHeight * 1.7}>
                    <Image
                        classNameProgressive="cardPicture"
                        fullHeight={fullHeight}
                        image={imageId}
                        placeholderBgColor={
                            itemImage?.colors?.[itemImage?.colors?.length - 1]?.hex || rest?.placeholderBgColor
                        }
                        alt={title}
                        ratio={cardVariationHandler(variant)}
                        height={imageHeight}
                        width={imageWidth}
                    />
                </ConditionalWrapper>
                {(title || category || label) && (
                    <div className="cardContent">
                        {(label || category) && (
                            <HeadingLabel
                                isUppercase={labelVariant === headingLabelVariants.SECONDARY}
                                className="cardContent_label"
                                label={label || category}
                                variant={labelVariant}
                                size={inspirationLabelSizeVariants[variant]}
                            />
                        )}
                        {title && <HighLightContent />}
                        {lead &&
                            [
                                inspirationCardVariants['2x1'],
                                inspirationCardVariants['2x2'],
                                inspirationCardVariants['1x2']
                            ].includes(variant) && (
                                <Media greaterThan="md">
                                    <div className="cardLead">{excerpt(lead, true)}</div>
                                </Media>
                            )}
                    </div>
                )}
            </InspirationCardStyled>
        </Card>
    )
}

InspirationCard.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    imageId: PropTypes.string,
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    itemId: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    label: PropTypes.string,
    labelVariant: PropTypes.oneOf([...Object.values(headingLabelVariants)]),
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    authorId: PropTypes.string,
    className: PropTypes.string,
    categoryName: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(inspirationCardVariants)]),
    fullHeight: PropTypes.bool,
    overrideImagePadding: PropTypes.number,
    isParallaxed: PropTypes.bool
}

InspirationCard.defaultProps = {
    item: undefined,
    imageId: undefined,
    imageWidth: 390,
    imageHeight: 360,
    itemId: undefined,
    title: undefined,
    titleTagComponent: 'h3',
    lead: undefined,
    label: undefined,
    labelVariant: headingLabelVariants.PRIMARY,
    href: undefined,
    hrefAs: undefined,
    authorId: undefined,
    className: undefined,
    categoryName: undefined,
    variant: inspirationCardVariants['1x1'],
    fullHeight: false,
    overrideImagePadding: undefined,
    isParallaxed: false
}

export { inspirationCardVariants }

export default withErrorBoundary(InspirationCard)
