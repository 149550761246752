/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { shallowEqual, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { useOneElastic } from '@hmn/coolinarika-web-core/hooks'

import { ArticleCard } from '../../../Article/components/Card'
import { withErrorBoundary } from '../../../ErrorBoundary'
import styles from './AdSlot.style'

const useWtg = process.env.NEXT_PUBLIC_GLOBAL_BANNERS_USE_WTG === 'true'
const chanceForNativeArticle = process.env.NEXT_PUBLIC_NATIVE_ARTICLE_CHANCE

// https://github.com/wmcmurray/just-detect-adblock

const AdSlotStyled = styled.div(props => ({ ...styles(props) }))

const adSlotBgVariants = Object.freeze({
    '1x1': '1x1',
    '1x2': '1x2',
    '2x1': '2x1'
})

const AdSlot = ({
    className,
    variant,
    position,
    isRecipeStep,
    isNativeSupported,
    isInterstitial,
    lazyLoad,
    ...rest
}) => {
    const { isNavigating } = useSelector(state => state.navigation)
    const { isLoaded, isWtgLoaded } = useSelector(state => state.settings, shallowEqual)
    const { asPath } = useRouter()
    const { sizes, adUnit, slotId, programmaticId } = rest
    const [isNativeArticle, setIsNativeArticle] = useState(false)
    const [isRealBanner, setIsRealBanner] = useState(false)
    const [ref, inView] = useInView({ triggerOnce: true, skip: !lazyLoad })

    const shouldAdLoad = lazyLoad ? inView : true

    const { data: nativeArticle } = useOneElastic({
        resource: 'recepti/native-promo-secondary',
        enabled: isNativeArticle
    })

    // @TODO: Temporary workaround to remove _XXX added to slotId's for programmatic testing purposes
    const actualSlotId = useWtg ? slotId : (slotId || '').split('_')?.[0]

    useEffect(() => {
        // Random chance to show native article, we don't worry about SSR because this is client side only
        if (isNativeSupported && Math.random() <= parseFloat(chanceForNativeArticle || 0.15) && !isNativeArticle) {
            setIsNativeArticle(true)
        } else {
            setIsRealBanner(true)
        }
    }, [isNativeSupported])

    useEffect(() => {
        if (
            !isNativeArticle &&
            isRealBanner &&
            !isNavigating &&
            shouldAdLoad &&
            typeof window !== 'undefined' &&
            isLoaded &&
            useWtg &&
            (isWtgLoaded || window?.w2g?.loaded)
        ) {
            const event = new CustomEvent('w2g', {
                detail: {
                    id: programmaticId,
                    state: 'did_mount'
                }
            })

            window.dispatchEvent(event)
            // console.warn(`[AdSlot]: Container ${programmaticId} available`)
        }
    }, [isNativeArticle, isRealBanner, isNavigating, shouldAdLoad, asPath, programmaticId, isLoaded, isWtgLoaded])

    useEffect(() => {
        if (
            !isNativeArticle &&
            isRealBanner &&
            !isNavigating &&
            typeof window !== 'undefined' &&
            window?.googletag &&
            isLoaded &&
            shouldAdLoad &&
            !useWtg &&
            actualSlotId?.length &&
            adUnit
        ) {
            const { googletag } = window

            googletag.cmd.push(() => {
                if (isInterstitial) {
                    googletag
                        .defineOutOfPageSlot(`/11539325/${adUnit}`, `${actualSlotId}`)
                        ?.addService(googletag.pubads())
                } else {
                    googletag
                        .defineSlot(`/11539325/${adUnit}`, sizes, `${actualSlotId}`)
                        ?.addService(googletag.pubads())
                }
                googletag.pubads().enableAsyncRendering()
                googletag.pubads().enableSingleRequest()
                googletag.enableServices()
                // console.warn('[AdSlot]: Displaying slot', adUnit, actualSlotId)
                googletag.display(`${actualSlotId}`)
            })
        }
    }, [
        isNativeArticle,
        isRealBanner,
        sizes,
        adUnit,
        slotId,
        actualSlotId,
        isNavigating,
        asPath,
        isInterstitial,
        isLoaded,
        shouldAdLoad,
        useWtg
    ])

    if (isNativeSupported && isNativeArticle && !!Object.keys(nativeArticle || {})?.length) {
        return (
            <AdSlotStyled className={className} variant={variant} isRecipeStep={isRecipeStep} ref={ref} {...rest}>
                <div style={{ height: 250, width: 300 }}>
                    <ArticleCard
                        item={nativeArticle}
                        isFeedArticle
                        href={`/blog/${
                            nativeArticle?.taxons?.['coolinarika-article-category']?.[0]?.slug || 'coolinarika'
                        }/${nativeArticle?.slug ? `${nativeArticle?.slug}-` : ''}${nativeArticle?.id}`}
                        titleTagComponent="h3"
                        imageWidth={390}
                        imageHeight={360}
                        variant="1x1"
                        fullHeight
                    />
                </div>
            </AdSlotStyled>
        )
    }

    if (isInterstitial) {
        return (
            <div>
                <div id={actualSlotId} />
            </div>
        )
    }

    if (isRealBanner) {
        return (
            <AdSlotStyled className={className} variant={variant} isRecipeStep={isRecipeStep} ref={ref} {...rest}>
                <div className="adunitContainer" id={actualSlotId} />
            </AdSlotStyled>
        )
    }

    return (
        <AdSlotStyled className={className} variant={variant} isRecipeStep={isRecipeStep} ref={ref} {...rest}>
            <div style={{ height: 250, width: 300 }} />
        </AdSlotStyled>
    )
}

AdSlot.propTypes = {
    position: PropTypes.string,
    isInterstitial: PropTypes.bool,
    lazyLoad: PropTypes.bool,
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(adSlotBgVariants)]),
    isRecipeStep: PropTypes.bool,
    isNativeSupported: PropTypes.bool
}

AdSlot.defaultProps = {
    position: undefined,
    isInterstitial: false,
    className: undefined,
    variant: adSlotBgVariants['1x1'],
    isRecipeStep: false,
    lazyLoad: false,
    isNativeSupported: false
}

export { adSlotBgVariants }

export default withErrorBoundary(AdSlot, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // Do something with the error, e.g. log it to Sentry or console, defaults to console
        // eslint-disable-next-line no-console
        console.error('[AdSlot]: ', error, componentStack)
    }
})
