/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const CheckMark = createSvgIcon(
    <path
        d="M24.598 9.276a.94.94 0 010 1.33L13.31 21.895a.94.94 0 01-1.33 0L7.275 17.19a.94.94 0 011.33-1.33l4.038 4.038L23.268 9.276a.94.94 0 011.33 0z"
        fill="#141313"
    />,
    'CheckMark'
)

export default CheckMark
