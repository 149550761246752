import { toRem, toRems } from '../../../../helpers/theme'

const Style = ({ theme, variant, cardType, fullHeight }) => {
    // Basic config for all cards
    const config = {
        '1x1': {
            mobile: {
                card: {
                    padding: toRems([25, 30])
                },
                content: {
                    direction: 'column',
                    aspectRatio: '1',
                    alignItems: 'flex-start',
                    lead: toRem(16)
                },
                button: {
                    marginTop: toRem(10)
                }
            },
            desktop: {
                minHeight: '125px',
                card: {
                    aspectRatio: '1',
                    alignItems: 'flex-start',
                    padding: toRems([40, 50])
                },
                content: {
                    direction: 'column',
                    lead: toRem(18)
                },
                button: {
                    marginTop: toRem(35)
                }
            }
        },
        '1x2': {
            mobile: {
                card: {
                    padding: toRems([25, 30])
                },
                content: {
                    direction: 'column',
                    paddingBottom: 'calc((504/601) * 100%)',
                    lead: toRem(16)
                },
                button: {
                    marginTop: toRem(10)
                }
            },
            desktop: {
                minHeight: '520px',
                card: {
                    padding: toRems([40, 50])
                },
                content: {
                    direction: 'column',
                    lead: toRem(18)
                },
                button: {
                    marginTop: toRem(35)
                }
            }
        },
        '2x1': {
            mobile: {
                card: {
                    padding: toRems([25, 30])
                },
                content: {
                    direction: 'column',
                    paddingBottom: 'calc((504/601) * 100%)',
                    lead: toRem(16)
                },
                button: {
                    marginTop: toRem(10)
                }
            },
            desktop: {
                minHeight: '250px',
                card: {
                    padding: toRem(40)
                },
                content: {
                    direction: 'column',
                    width: '60%',
                    paddingBottom: 0,
                    lead: toRem(18)
                },
                button: {
                    marginTop: toRem(20)
                }
            }
        },
        fullWidth: {
            mobile: {
                card: {
                    width: '100%',
                    padding: toRems([80, 25])
                },
                content: {
                    direction: 'column',
                    paddingBottom: 'calc((504/601) * 100%)',
                    lead: toRem(18),
                    textAlign: 'center'
                },
                button: {
                    marginTop: toRem(25)
                }
            },
            desktop: {
                card: {
                    width: '100%',
                    padding: toRems([85, 135])
                },
                content: {
                    direction: 'column',
                    width: '60%',
                    paddingBottom: '15%',
                    lead: toRem(22),
                    textAlign: 'left'
                },
                button: {
                    marginTop: toRem(35)
                }
            }
        }
    }

    // Card specific config
    const cardTypeConfig = {
        registration: {
            size: {
                '1x1': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-registration-cta.png)',
                            backgroundPosition: '90% bottom',
                            backgroundSize: '58%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-registration-cta.png)',
                            backgroundPosition: '90% bottom',
                            backgroundSize: '58%'
                        }
                    }
                },
                '1x2': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-registration-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-registration-cta.png)',
                            backgroundPosition: 'center 95%',
                            backgroundSize: '90%'
                        }
                    }
                },
                '2x1': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-registration-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-registration-cta.png)',
                            backgroundPosition: 'center right',
                            backgroundSize: '346px 301px'
                        },
                        content: {}
                    }
                },
                fullWidth: {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-registration-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-registration-cta.png)',
                            backgroundPosition: 'center right',
                            backgroundSize: 'contain'
                        },
                        content: {}
                    }
                }
            }
        },
        user_profile: {
            size: {
                '1x1': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-user-profile-cta.png)',
                            backgroundPosition: 'right bottom',
                            backgroundSize: '90%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundPosition: 'right bottom',
                            backgroundSize: '85%'
                        }
                    }
                },
                '1x2': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-user-profile-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        }
                    }
                },
                '2x1': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-user-profile-cta-rotated.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundPosition: 'left center',
                            backgroundSize: 'contain'
                        },
                        content: {
                            marginLeft: 'auto'
                        }
                    }
                },
                fullWidth: {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-user-profile-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundPosition: 'center right',
                            backgroundSize: 'contain'
                        },
                        content: {}
                    }
                }
            }
        },
        taste_profile: {
            size: {
                '1x1': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-taste-profile-inverted-cta.png)',
                            backgroundPosition: '150px bottom',
                            backgroundSize: '120%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-taste-profile-inverted-cta.png)',
                            backgroundPosition: '500% bottom',
                            backgroundSize: '90%'
                        }
                    }
                },
                '1x2': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-taste-profile-mobile-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-taste-profile-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        }
                    }
                },
                '2x1': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-taste-profile-mobile-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-taste-profile-inverted-cta.png)',
                            backgroundPosition: 'left bottom',
                            backgroundSize: 'contain'
                        },
                        content: {
                            marginLeft: 'auto'
                        }
                    }
                },
                fullWidth: {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-taste-profile-mobile-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-taste-profile-cta.png)',
                            backgroundPosition: 'right bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    }
                }
            }
        },
        newsletter: {
            size: {
                '1x1': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-newsletter-cta.png)',
                            backgroundPosition: 'right bottom',
                            backgroundSize: '50%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-newsletter-cta.png)',
                            backgroundPosition: 'right bottom',
                            backgroundSize: '50%'
                        }
                    }
                },
                '1x2': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-newsletter-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-newsletter-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        }
                    }
                },
                '2x1': {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-newsletter-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-newsletter-cta.png)',
                            backgroundPosition: 'center right',
                            backgroundSize: 'contain'
                        },
                        content: {}
                    }
                },
                fullWidth: {
                    mobile: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-newsletter-cta.png)',
                            backgroundPosition: 'center bottom',
                            backgroundSize: '100%'
                        },
                        content: {}
                    },
                    desktop: {
                        card: {
                            backgroundImage: 'url(/images/placeholder/placeholder-newsletter-cta.png)',
                            backgroundPosition: 'center right',
                            backgroundSize: 'contain'
                        },
                        content: {}
                    }
                }
            }
        }
    }

    return {
        position: 'relative',
        width: config[variant]?.mobile?.card?.width,
        padding: config[variant]?.mobile?.card?.padding,
        backgroundImage: cardTypeConfig[cardType]?.size[variant]?.mobile?.card?.backgroundImage,
        backgroundColor: theme.colors.card.promotion[cardType]?.background,
        backgroundPosition: cardTypeConfig[cardType]?.size[variant]?.mobile?.card?.backgroundPosition,
        backgroundSize: cardTypeConfig[cardType]?.size[variant]?.mobile?.card?.backgroundSize,
        backgroundRepeat: 'no-repeat',
        ...(fullHeight && {
            height: '100%'
        }),
        [theme.breakpoints.up('md')]: {
            width: config[variant]?.desktop?.card?.width,
            ...(config.full && {
                minHeight: toRem(585)
            }),
            minHeight: config[variant]?.desktop.minHeight,
            padding: config[variant]?.desktop?.card?.padding,
            backgroundImage: cardTypeConfig[cardType]?.size[variant]?.desktop?.card?.backgroundImage,
            backgroundPosition: cardTypeConfig[cardType]?.size[variant]?.desktop?.card?.backgroundPosition,
            backgroundSize: cardTypeConfig[cardType]?.size[variant]?.desktop?.card?.backgroundSize
        },
        borderRadius: theme.global.borderRadius.medium,
        '.card_content': {
            display: 'flex',
            flexDirection: config[variant]?.mobile?.content?.direction,
            aspectRatio: config[variant]?.mobile.content.aspectRatio,
            alignItems: config[variant]?.mobile.content.alignItems,
            width: config[variant]?.mobile?.content?.width,
            height: '100%',
            paddingBottom: config[variant]?.mobile?.content?.paddingBottom,
            textAlign: config[variant]?.mobile?.content?.textAlign,
            [theme.breakpoints.up('md')]: {
                flexDirection: config[variant]?.desktop?.content?.direction,
                width: config[variant]?.desktop?.content?.width,
                marginLeft: cardTypeConfig[cardType]?.size[variant]?.desktop?.content?.marginLeft,
                paddingBottom: config[variant]?.desktop?.content?.paddingBottom,
                textAlign: config[variant]?.desktop?.content?.textAlign
            },
            color: theme.colors.informationCard.text
        },
        '.cardContent_title': {
            color: 'inherit',
            marginBottom: toRem(5)
        },
        '.cardContent_text': {
            fontSize: config[variant]?.mobile?.content?.lead,
            [theme.breakpoints.up('md')]: {
                fontSize: config[variant]?.desktop?.content?.lead
            }
        },
        '.cardContent_buttonContainer': {
            marginTop: config[variant]?.mobile?.button?.marginTop,
            [theme.breakpoints.up('md')]: {
                marginTop: config[variant]?.desktop?.button?.marginTop
            }
        }
    }
}

export default Style
