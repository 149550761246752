import { useCallback } from 'react'
import { batch } from 'react-redux'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { DRAWER_ACTIONS, useDrawerDispatch } from '@hmn/coolinarika-web-core/context/Drawer'
import { md, useBreakpoints, useUser } from '@hmn/coolinarika-web-core/hooks'

import { Button, buttonSizes, buttonVariants } from '../../../Button'
import { withErrorBoundary } from '../../../ErrorBoundary'
import { HeadingSection, headingSectionSizes } from '../../../Heading/components/Section'
import { ArrowSmallIcon, Icon } from '../../../Icon'
import styles from './Card.style'

const PromotionCardStyled = styled.div(props => ({ ...styles(props) }))
const promotionCardTypeVariant = Object.freeze({
    REGISTRATION: 'registration',
    USER_PROFILE: 'user_profile',
    TASTE_PROFILE: 'taste_profile',
    NEWSLETTER: 'newsletter'
})
const promotionCardTypeCopy = Object.freeze({
    [promotionCardTypeVariant.REGISTRATION]: {
        title: 'Kreiraj vlastite kolekcije sadržaja!',
        lead:
            // eslint-disable-next-line max-len
            'Registrirani korisnici mogu stvarati svoje, pametne kolekcije. Spremanje omiljenih recepata na ovaj način je super korisno. Probaj! ',
        buttonTitle: 'Registriraj se',
        actionDrawer: 'drawer-auth-register'
    },
    [promotionCardTypeVariant.USER_PROFILE]: {
        title: 'Predstavi se drugim korisnicima.',
        lead:
            // eslint-disable-next-line max-len
            'Ispuni svoj osobni profil, reci ponešto o sebi i predstavi se drugim korisnicima. Pokaži tko si i što sve znaš!',
        buttonTitle: 'Kreni',
        actionDrawer: 'drawer-survey-profile-information'
    },
    [promotionCardTypeVariant.TASTE_PROFILE]: {
        // title: 'Odgovori na nekoliko pitanja i uživaj u tebi prilagođenim receptima.',
        title: 'Ispuni upitnik i uživaj u tebi prilagođenim receptima.',
        // eslint-disable-next-line max-len
        lead: 'Reci nam koje namirnice posebno voliš, koje ti se baš ne sviđaju, koji je tvoj nivo kulinarskog znanja...',
        buttonTitle: 'Kreni',
        actionDrawer: 'taste-profile-survey'
    },
    [promotionCardTypeVariant.NEWSLETTER]: {
        title: 'Hej, zašto se ne prijaviš na Coolinarika newsletter?',
        lead: 'Sadržaj je pomno biran, prati sezone i trendove te donosi izvrsne kulinarske ideje.',
        buttonTitle: 'Prijavi se',
        actionDrawer: 'drawer-edit-settings-newsletter'
    }
})

const promotionCardSizeVariant = Object.freeze({
    '1x2': '1x2',
    '1x1': '1x1',
    '2x1': '2x1',
    fullWidth: 'fullWidth'
})

const drawerAvailableForAnonymousUsers = cardType =>
    cardType === promotionCardTypeVariant.NEWSLETTER || cardType === promotionCardTypeVariant.TASTE_PROFILE

const PromotionCard = ({
    title,
    lead,
    buttonTitle,
    onButtonClick,
    variant,
    cardType,
    className,
    fullHeight,
    ...props
}) => {
    const dispatch = useDrawerDispatch()
    const { isLoggedIn } = useUser()
    const [isMobileUp] = useBreakpoints(md)
    const buttonSizeVariants = Object.freeze({
        '1x2': buttonSizes[isMobileUp ? 'LARGE' : 'NORMAL'],
        '2x1': buttonSizes[isMobileUp ? 'LARGE' : 'NORMAL'],
        fullWidth: buttonSizes.LARGE
    })

    const onClickAction = useCallback(() => {
        if (isLoggedIn || drawerAvailableForAnonymousUsers(cardType)) {
            batch(() => {
                dispatch({
                    type: DRAWER_ACTIONS.OPEN_DRAWER,
                    payload: {
                        drawerType: promotionCardTypeCopy[cardType].actionDrawer
                    }
                })

                dispatch({
                    type: DRAWER_ACTIONS.SET_ON_SUCCESS_DRAWER,
                    payload: {
                        onSuccessDrawer: false
                    }
                })
            })
        } else {
            batch(() => {
                dispatch({
                    type: DRAWER_ACTIONS.OPEN_DRAWER,
                    payload: {
                        drawerType: 'drawer-auth-register'
                    }
                })

                dispatch({
                    type: DRAWER_ACTIONS.SET_ON_SUCCESS_DRAWER,
                    payload: {
                        onSuccessDrawer: promotionCardTypeCopy[cardType].actionDrawer
                    }
                })
            })
        }
    }, [dispatch, cardType, isLoggedIn])
    const sectionTitle = title || promotionCardTypeCopy[cardType].title
    const sectionLead = lead || promotionCardTypeCopy[cardType].lead
    const sectionButtonTitle = buttonTitle || promotionCardTypeCopy[cardType].buttonTitle

    return (
        <PromotionCardStyled
            variant={variant}
            cardType={cardType}
            className={className}
            fullHeight={fullHeight}
            {...props}>
            <div className="card_content">
                {sectionTitle && (
                    <HeadingSection className="cardContent_title" size={headingSectionSizes.SMALLER}>
                        {sectionTitle}
                    </HeadingSection>
                )}

                {sectionLead && <p className="cardContent_text">{sectionLead}</p>}

                {sectionButtonTitle && (
                    <div className="cardContent_buttonContainer">
                        <Button
                            title={sectionButtonTitle}
                            variant={buttonVariants.PRIMARY}
                            widthMobile={promotionCardSizeVariant.fullWidth && !isMobileUp ? '100%' : undefined}
                            size={buttonSizeVariants[variant]}
                            iconRightComponent={<Icon icon={ArrowSmallIcon} />}
                            onClick={onButtonClick || onClickAction}>
                            {sectionButtonTitle}
                        </Button>
                    </div>
                )}
            </div>
        </PromotionCardStyled>
    )
}

PromotionCard.propTypes = {
    title: PropTypes.string,
    lead: PropTypes.string,
    buttonTitle: PropTypes.string,
    onButtonClick: PropTypes.func,
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(promotionCardSizeVariant)]),
    cardType: PropTypes.oneOf([...Object.values(promotionCardTypeVariant)]),
    fullHeight: PropTypes.bool
}

PromotionCard.defaultProps = {
    title: undefined,
    lead: undefined,
    buttonTitle: undefined,
    onButtonClick: undefined,
    className: undefined,
    variant: promotionCardSizeVariant['1x2'],
    cardType: promotionCardTypeVariant.NEWSLETTER,
    fullHeight: false
}

export { promotionCardTypeVariant, promotionCardSizeVariant }

export default withErrorBoundary(PromotionCard)
